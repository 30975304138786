import React, { FC } from 'react';

import {
  Box,
  Container,
  Flex,
  // Grid,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
// import { useTranslation } from 'react-i18next';

// import { PATHNAMES } from '@/lib/routes';

import LayersAnimation from '../Animations/LayersAnimation';
// import TextBox from '../TextBox/TextBox';

/**
 * Note for Box around the Image:
 * On small devices, we want to force width: 80% and progressively make it less.
 * Also, we add a margin-bottom on small screens to make the space between the image and the content larger.
 */
type CTAWithImageProps = TODO;

export const CTAWithImage: FC<CTAWithImageProps> = ({ heading, children, ...rest }) => {
  // const { t } = useTranslation();

  return (
    <Container as="div" maxW={'8xl'} {...rest}>
      <Stack
        align={{ base: 'center', md: 'flex-end' }}
        spacing={{ base: 8, md: 5, lg: 0 }}
        pt={{ base: 10, md: 20 }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex
          flex={1}
          justify={'center'}
          align={{ base: 'center' }}
          position={'relative'}
          w={'full'}
        >
          <Box
            position={'relative'}
            height={'l'}
            mb={{ base: 0, md: 8, lg: 20 }}
          >
            <LayersAnimation />
          </Box>
        </Flex>

        <Stack
          flex={1}
          pb={20}
          spacing={{ base: 5, md: 4 }}
          align="flex-start"
          w="full"
          pt={{ base: 0, sm: 0, lg: '25vh' }}
        >
          <Heading
            as="h1"
            color={'white'}
            textStyle="heroHeader"
            size="2xl"
            lineHeight="short"
            textTransform="uppercase"
            pl={{ base: 5, md: 0 }}
            pt={{ base: 0, md: 5 }}
          >
            {heading}
          </Heading>
          {/* <Text as="p" textStyle="heroAnnotation" color={'white'}>
              Lorem ipsum dolor sit amet, consectetur adipisici elit, sed
              </Text> */}
          <Text
            as="p"
            textStyle="heroContent"
            color={'white'}
            pl={{ base: 5, md: 0 }}
            pr={5}
            minHeight="20rem"
          >
            {children}{' '}
          </Text>
          {/* <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
              gap={6}
              >
              <TextBox
              heading="Získejte plné<br> členství"
              href={PATHNAMES.projects}
              h="full"
              w={{ base: 'full' }}
              buttonVariant="@box"
              >
              <ul>
              <li>consectetuer, adipiscing elit.</li>
              <li>
              Lorem ipsum dolor sit amet, consectetuer, adipiscing elit.
              </li>
              <li>
              Lorem ipsum dolor sit amet, consectetuer, adipiscing elit.
              </li>
              <li>
              Lorem ipsum dolor sit amet, consectetuer, adipiscing elit.
              </li>
              </ul>
              </TextBox>
              <TextBox
              heading="Vyzkoušejte<br> zdarma"
              href={PATHNAMES.projects}
              bg="#e2e2e2"
              h="full"
              w={{ base: 'full' }}
              buttonVariant="@boxAlt"
              >
              <ul>
              <li>
              Lorem ipsum dolor sit amet, consectetuer, adipiscing elit.
              </li>
              <li>
              Lorem ipsum dolor sit amet, consectetuer, adipiscing elit.
              </li>
              </ul>
              </TextBox>
              </Grid> */}
        </Stack>
      </Stack>
    </Container>
  );
};
