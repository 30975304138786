/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Box, BoxProps, HStack, VStack } from '@chakra-ui/layout';
import type { ChakraComponent } from '@chakra-ui/react';
import { LazyMotion, m, useCycle } from 'framer-motion';
import Image from 'next/image';

const loadFramerFeatures = () =>
  import('@/lib/framerFeatures').then((res) => res.default);

export const MotionBox = m<Omit<BoxProps, 'transition'>>(Box);

export const LayersAnimation: ChakraComponent<typeof HStack, any /* Props */> =
  ({ ...rest }) => {
    const [twist1, cycle1] = useCycle(-40, 42);
    const [twist2, cycle2] = useCycle(0, -10);
    const [twist3, cycle3] = useCycle(0, -60);
    const [twist4, cycle4] = useCycle(-40, -110);
    const [twist5, cycle5] = useCycle(1.5, 1);
    const [twist6, cycle6] = useCycle(1, 0.7);

    const runAnimation = () => {
      cycle1();
      cycle2();
      cycle3();
      cycle4();
      cycle5();
      cycle6();
    };

    useEffect(() => {
      const interval = setInterval(runAnimation, 2000);
      return () => clearInterval(interval);
    }, [runAnimation]);

    const transition = {
      duration: 0.7,
      /* loop: Infinity,
       * repeatType: 'loop' as 'loop',
       */
    };

    const x = 1.2;

    return (
      <LazyMotion features={loadFramerFeatures}>
        <HStack alignItems="flex-end" {...rest}>
          <Image
            src="/project/l.svg"
            width={220}
            height={268}
            alt="animation"
          />
          <VStack spacing={1} onClick={runAnimation}>
            <MotionBox
              boxSize="35px"
              initial={{ y: -40 }}
              animate={{ y: twist1 }}
              transition={transition}
              zIndex={5}
            >
              <Image
                src="/project/l1.svg"
                width={68}
                height={60}
                alt="animation"
              />
            </MotionBox>
            <MotionBox
              boxSize="78px"
              animate={{ y: twist2 }}
              transition={transition}
              zIndex={4}
            >
              <Image
                src="/project/l2.svg"
                width={78 * x}
                height={62 * x}
                alt="animation"
              />
            </MotionBox>
            <MotionBox
              boxSize="78px"
              animate={{ y: twist3 }}
              transition={transition}
              zIndex={3}
            >
              <Image
                src="/project/l3.svg"
                width={78 * x}
                height={62 * x}
                alt="animation"
              />
            </MotionBox>
            <MotionBox
              boxSize="78px"
              initial={{ y: -40 }}
              animate={{ y: twist4 }}
              transition={transition}
              zIndex={2}
            >
              <Image
                src="/project/l3.svg"
                width={78 * x}
                height={62 * x}
                alt="animation"
              />
            </MotionBox>
            <MotionBox
              initial={{ scale: 1.5, opacity: 1, y: 0 }}
              animate={{ scale: twist5, opacity: twist6 }}
              transition={transition}
              zIndex={1}
            >
              <Image
                src="/project/l4.svg"
                width={78 * x}
                height={62 * x}
                alt="animation"
              />
            </MotionBox>
          </VStack>
          <Image
            src="/project/r.svg"
            width={220}
            height={268}
            alt="animation"
          />
        </HStack>
      </LazyMotion>
    );
  };

export default LayersAnimation;
