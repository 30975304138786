import { useState, useRef } from 'react';
import { Box, Container, Flex, Heading, Image, Button } from '@chakra-ui/react';
import Link from 'next/link';

import { CTAWithImage } from '@/components/CTA/CTAWithImage';
import Footer from '@/components/Footer/Footer';
import { Logo } from '@/components/Logo';
import LandingHeader from '@/components/Nav/LandingHeader';
import PublicProjectsTiles from '@/components/Project/PublicProjectsTiles';
import { useScroll } from '@/hooks/useScroll';
import { ENABLE_PAGINATION, ENABLE_SEARCH, PAGE_SIZES } from '@/config/webSite';
import TopNews from '@/components/News/TopNews';
import { blue } from '@/theme/gradient';
import { PATHNAMES } from '@/lib/routes';

import type { FC } from 'react';


const ScrollDownIcon: FC<{ onClick: () => null }> = ({ onClick }) => {
  const image1 = 'url("/icon/mouse-up.svg")';
  const image2 = 'url("/icon/mouse-down.svg")';
  const [image, setImage] = useState(image1);

  return (
    <Image
      display={{ base: "none", lg: "initial" }}
      onClick={onClick}
      position="absolute"
      cursor="pointer"
      bottom="0"
      right="50%"
      src=""
      style={{ content: image }}
      onMouseEnter={() => setImage(image2)}
      onMouseOut={() => setImage(image1)}
      width={12}
      alt="Novinky"
    />
  );
};

const LandingPage = () => {
  const { scrollTop } = useScroll(250);
  const ref = useRef<null | HTMLDivElement>(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
    return null;
  };

  return (
    <>
      <Box
        minH={'10vh'}
        bgColor={'brand.600'}
        style={{
          background: blue,
        }}
        sx={{ borderBottom: '0px solid red' }}
      >
        <LandingHeader
          position={{ base: 'initial', lg: 'fixed' }}
          zIndex="100"
          boxShadow={scrollTop && '1px 1px 1px #0000001f'}
          transition="box-shadow 0.3s ease-in-out"
          style={{
            background: blue,
          }}
        >
          <Link prefetch={false} href="/" passHref>
            <Logo
              title="Homepage"
              alt="Homepage"
              ml={6}
              w={{ base: '116px', md: '116px' }}
              color={['white', 'white', 'primary.500', 'primary.500']}
            />
          </Link>
        </LandingHeader>
        <Flex
          zIndex="1"
          px={{ base: 0, sm: 8, lg: 0 }}
          mb={8}
          align="center"
          justify={{ base: 'center', md: 'space-between', xl: 'space-between' }}
          position="relative"
        >
          <CTAWithImage heading="Smart City Hub">
            Smart City Hub je interaktivní databáze projektů Smart City
            zahrnující projekty realizované nejen v Česku, ale i v zahraničí.
            Cílem Smart City Hub je především šíření inovativních projektů a
            chytrých řešení a slouží k přehledné evidenci informací, aktuálních
            trendů a strategií.
          </CTAWithImage>
          <ScrollDownIcon onClick={() => handleClick()} />
        </Flex>
      </Box>
      <Box ref={ref}></Box>

      <Container maxW="12xl" bg="#f7f7f7" mx="auto" pb={8} px={8} textAlign="left">
        <Heading
          mx="auto"
          size="3xl"
          textTransform="uppercase"
          fontSize="xl"
          pt={{ base: 2, sm: '4rem' }}
          pb={{ base: 12, sm: '2rem' }}
          textAlign="left"
        >
          Databáze projektů
        </Heading>
        <Box ml={0} mr="auto" maxW="5xl" fontSize="md" textAlign="left">
          Databáze Smart City Hub obsahuje několik set inovativních projektů,
          které úspěšně prošly hodnocením Odborné poroty v některém z ročníků
          soutěže Chytrá města, a tak získaly oficiální certifikaci kvality
          projektu Smart City. Soutěž je každoročně pořádána neziskovou
          organizací Smart City Innovations Institut, z.ú., v úzké spolupráci s
          Ministerstvem pro místní rozvoj ČR, Ministerstvem průmyslu a obchodu
          ČR, Svazem měst a obcí ČR, vysokými školami a dalšími odbornými
          institucemi. Smart City Hub tedy slouží především jako zdroj inspirace
          a informací, které je možné využít při plánování inovativních řešení,
          a nabízí propojení poskytovatelů těchto řešení s investory a veřejnými
          subjekty.
        </Box>
      </Container>

      <PublicProjectsTiles
        enablePagination={ENABLE_PAGINATION}
        enableSearch={ENABLE_SEARCH}
        pageSizes={PAGE_SIZES}
      />

      <Link prefetch={false} href={PATHNAMES.projects} passHref>
        <Button colorScheme="brand" m="auto" borderRadius="full" px={8} my={8}>
          Katalog projektů
        </Button>
      </Link>

      <Box h={16}></Box>
      <Footer />
    </>
  );
};

export default LandingPage;
