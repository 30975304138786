import { useEffect, useMemo, useRef, useState } from 'react';

export const getScrollPosition = () => {
  if (typeof window === 'undefined') {
    return 0;
  }
  return (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  );
};

export function throttle(callback: Function, delay: number) {
  let timeoutHandler: ReturnType<typeof setTimeout> | null = null;
  return () => {
    if (timeoutHandler == null) {
      timeoutHandler = setTimeout(() => {
        callback();
        timeoutHandler = null;
      }, delay);
    }
  };
}

// https://www.pinkdroids.com/blog/moving-header-react-hooks-context/

export const useScroll = (timeout = 250) => {
  const defaultScrollTop = useMemo(() => getScrollPosition(), []);
  const previousScrollTop = useRef(defaultScrollTop);
  const [currentScrollTop, setCurrentScrollTop] = useState(defaultScrollTop);

  useEffect(
    () => {
      const handleDocumentScroll = () => {
        const scrollTop = getScrollPosition();
        setCurrentScrollTop(scrollTop);
        previousScrollTop.current = scrollTop;
      };

      const handleDocumentScrollThrottled = throttle(
        handleDocumentScroll,
        timeout
      );
      setTimeout(() => {
        window.addEventListener('scroll', handleDocumentScrollThrottled);
      }, timeout);

      return () => {
        window.removeEventListener('scroll', handleDocumentScrollThrottled);
      };
    } /* [timeout] */
  );

  return {
    scrollTop: currentScrollTop,
    previousScrollTop: previousScrollTop.current,
    time: timeout,
  };
};
