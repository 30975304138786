import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

/**
 * Note for Box around the Image:
 * On small devices, we want to force width: 80% and progressively make it less.
 * Also, we add a margin-bottom on small screens to make the space between the image and the content larger.
 */

const Footer = () => {
  return (
    <VStack spacing={0}>
      <HStack
        spacing={6}
        align="center"
        justify="center"
        width="100%"
        bg="#0a0a0b"
        py={12}
      >
        <Link prefetch={false} href="/" passHref>
          <Box>
            <Image
              src="/project/facebook.svg"
              width={30}
              height={30}
              alt="Facebook"
              title="Facebook"
            />
          </Box>
        </Link>
        <Link prefetch={false} href="/" passHref>
          <Box>
            <Image
              src="/project/twitter.svg"
              width={35}
              height={35}
              alt="Twitter"
              title="Twitter"
            />
          </Box>
        </Link>
        <Link prefetch={false} href="/" passHref>
          <Box>
            <Image
              src="/project/instagram.svg"
              width={32}
              height={32}
              alt="Instagram"
              title="Instagram"
            />
          </Box>
        </Link>
        <Link prefetch={false} href="/" passHref>
          <Box>
            <Image
              src="/project/youtube.svg"
              width={35}
              height={35}
              alt="Youtube"
              title="Youtube"
            />
          </Box>
        </Link>
      </HStack>
      <HStack
        py={10}
        spacing="3rem"
        align="center"
        justify="center"
        width="100%"
        bg="#0a0a0b"
        color="#f7f7f7"
      >
        <Box borderBottom="1px solid #f7f7f7" pb={1} px={3} fontSize="xs">
          <Link prefetch={false} href="/pages/lorem">
            Ochrana osobních údajů
          </Link>
        </Box>
        {/* <Box borderBottom="1px solid #f7f7f7" pb={1} px={3} fontSize="xs">
            <Link prefetch={false} href="/pages/lorem">
            Obchodní podmínky
            </Link>
            </Box> */}
      </HStack>
      <HStack
        pb={12}
        spacing="1rem"
        align="center"
        justify="center"
        width="100%"
        bg="#0a0a0b"
        color="#f7f7f7"
      >
        <Text fontSize="sm">SMART CITY HUB - všechna práva vyhrazena</Text>
      </HStack>
    </VStack>
  );
};

export default Footer;
